import {
  ComponentClientSpecMapEntry,
  ComponentRef,
} from '@wix/editor-platform-sdk-types';
import {
  FEED_PAGE_SECTIONS,
  SECTION_POST_LIST,
  LAYOUT_NAME_PG_GRID,
  LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_NAME_LIST,
  LAYOUT_NAME_PG_CARD_MEDIUM,
  PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS,
  getLayoutCardsRowTypeParam,
} from '@wix/communities-blog-client-common';
import { EditorAppContext } from '../../../../types/editor-app-context.type';
import { blogAppDefId } from '../../constants/apps';
import { BLOG_WIDGET_ID, POST_LIST_WIDGET_ID } from '../../constants/widgets';
import { createUpdateStyleParams } from './create-update-style-params';

const feedSections = FEED_PAGE_SECTIONS;
const postListSection = [SECTION_POST_LIST];
const layoutNames = [
  LAYOUT_NAME_PG_GRID,
  LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_NAME_LIST,
  LAYOUT_NAME_PG_CARD_MEDIUM,
] as const;

export const makeStylesReducer = (acc: string[], section: string) => [
  ...acc,
  ...layoutNames.map((layoutName) =>
    getLayoutCardsRowTypeParam(section, layoutName),
  ),
];

const feedStyleKeys = feedSections.reduce(makeStylesReducer, []);
const postListStyleKeys = postListSection.reduce(makeStylesReducer, []);

const setFeedCardRowType = createUpdateStyleParams(
  PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.ITEMS_PER_ROW,
  'number',
  feedStyleKeys,
);
const setPostListCardRowType = createUpdateStyleParams(
  PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.ITEMS_PER_ROW,
  'number',
  postListStyleKeys,
);

const changeFeedContentHeightType = async (
  { appToken, sdk }: EditorAppContext,
  blogAppComponents: ComponentClientSpecMapEntry[],
) => {
  const component = blogAppComponents.find(({ widgetId }) =>
    [BLOG_WIDGET_ID].includes(widgetId),
  );

  if (!component) {
    return;
  }

  const compRef = await sdk.document.components.getById(appToken, {
    id: component.id,
  });

  await setFeedCardRowType(sdk, appToken, compRef);
};

const changePostListContentHeightType = async (
  context: EditorAppContext,
  blogAppComponents: ComponentClientSpecMapEntry[],
  newWidgetCompRef: ComponentRef,
) => {
  const component = blogAppComponents.find(
    (comp) => comp.id === newWidgetCompRef.id,
  );
  const newWidgetIsPostList = component?.widgetId === POST_LIST_WIDGET_ID;

  if (!newWidgetIsPostList) {
    return;
  }
  await setPostListCardRowType(context.sdk, context.appToken, {
    id: newWidgetCompRef.id,
    type: newWidgetCompRef.type,
  });
};

export const setDefaultCardsRowType = async (
  context: EditorAppContext,
  newWidgetCompRef?: ComponentRef,
) => {
  const { isClassicEditor, sdk, appToken } = context;
  if (!isClassicEditor) {
    return;
  }

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(
    appToken,
    blogAppDefId,
  );
  if (!blogAppData) {
    return;
  }
  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(
    appToken,
    blogAppData.applicationId,
  );

  if (newWidgetCompRef) {
    await changePostListContentHeightType(
      context,
      blogAppComponents,
      newWidgetCompRef,
    );
  } else {
    await changeFeedContentHeightType(context, blogAppComponents);
  }
};
