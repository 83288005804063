import { get, isUndefined } from 'lodash';
import { StyleParam } from '@wix/editor-platform-sdk-types';
import {
  IS_BLOG_MENU_SEARCH_ENABLED,
  IS_BLOG_MENU_SEARCH_ENABLED_MOBILE,
} from '@wix/communities-blog-client-common';
import { EditorAppContext } from '../../../../types/editor-app-context.type';
import { blogAppDefId } from '../../constants/apps';
import { BLOG_WIDGET_ID, POST_WIDGET_ID } from '../../constants/widgets';

export const setDefaultShowBlogSearch = async ({
  sdk,
  appToken,
}: EditorAppContext) => {
  const blogAppData = await sdk.tpa.app.getDataByAppDefId(
    appToken,
    blogAppDefId,
  );
  if (!blogAppData) {
    return;
  }
  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(
    appToken,
    blogAppData.applicationId,
  );

  const components = blogAppComponents.filter(({ widgetId }) =>
    [BLOG_WIDGET_ID, POST_WIDGET_ID].includes(widgetId),
  );

  for (const component of components) {
    const compRef = await sdk.document.components.getById(appToken, {
      id: component.id,
    });
    const styleParams = await sdk.document.tpa.getStyleParams(appToken, {
      compRef,
    });

    const changes: StyleParam[] = [];
    if (
      isUndefined(
        get(styleParams, `booleans.${IS_BLOG_MENU_SEARCH_ENABLED_MOBILE}`),
      )
    ) {
      changes.push({
        type: 'boolean',
        key: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE,
        param: { value: false },
      });
    }
    if (
      isUndefined(get(styleParams, `booleans.${IS_BLOG_MENU_SEARCH_ENABLED}`))
    ) {
      changes.push({
        type: 'boolean',
        key: IS_BLOG_MENU_SEARCH_ENABLED,
        param: { value: false },
      });
    }

    await sdk.document.tpa.setStyleParams(appToken, {
      compRef,
      styleParams: changes,
    });
  }
};
