import { get, isUndefined } from 'lodash';
import {
  ComponentRef,
  EditorSDK,
  StyleParam,
} from '@wix/editor-platform-sdk-types';

export const createUpdateStyleParams =
  (value: any, type: StyleParam['type'], styleKeys: string[]) =>
  async (sdk: EditorSDK, appToken: string, compRef: ComponentRef) => {
    const styleParams = await sdk.document?.tpa?.getStyleParams(appToken, {
      compRef,
    });
    const changedStyleParams = styleKeys?.reduce((acc, styleKey) => {
      if (isUndefined(get(styleParams, `numbers.${styleKey}`))) {
        acc.push({
          type,
          key: styleKey,
          param: { value },
        });
      }
      return acc;
    }, [] as StyleParam[]);
    if (changedStyleParams.length > 0) {
      await sdk.document.tpa.setStyleParams(appToken, {
        compRef,
        styleParams: changedStyleParams,
      });
    }
  };
